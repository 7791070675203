import get from 'lodash/get';
import moment from 'moment';
import { COST_TYPE } from 'utils/constants';

export const TESTCASE_DATE_FORMAT = 'YYYY-MM-DD h:mm';
export const DATE_PICKER_FORMAT = 'MM/DD/YYYY';
export const DATE_PICKER_MONTH_FORMAT = 'MMMM YYYY';
export const DATE_FORMAT = 'DD MMM, YYYY';
export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_YEAR_FORMAT = 'MMM YYYY';
export const FILTER_LAST_SCAN = 'DD MMM, YYYY (h:mm A)';
export const DATE_AND_TIME_FORMAT = 'DD MMM, YYYY (h:mm:ss A)';
export const DAY_DATE_AND_TIME_FORMAT = 'ddd DD MMM YYYY - h:mm A';
export const TODAY = moment.utc();
export const TOMORROW = moment.utc().add(1, 'day');
export const CURRENT_YEAR = moment().year();
export const YESTERDAY = TODAY.clone().subtract(1, 'day');
export const DAY_BEFORE_YESTERDAY = TODAY.clone().subtract(2, 'day');
export const WEEK_AGO = TODAY.clone().subtract(7, 'day');
export const TWO_WEEKS_AGO = TODAY.clone().subtract(14, 'days').utc();
export const START_OF_MONTH = moment.utc().startOf('month');
export const START_OF_LAST_MONTH = moment.utc().startOf('month').subtract(1, 'month');
export const END_OF_MONTH = moment.utc().endOf('month');
export const END_OF_LAST_MONTH = moment.utc().subtract(1, 'month').endOf('month');
export const ONE_MORE_MONTH_FROM_TODAY = moment.utc().add(29, 'day');
export const FIRST_DATE_OF_LAST_MONTH = TODAY.clone().subtract(1, 'month').set({ date: 1 });
export const LAST_30_DAYS = TODAY.clone().subtract(30, 'days');
export const FILTER_CONDITION_TYPE = {
  MUST: 'must',
  MUST_NOT: 'must_not',
  SHOULD: 'should',
  SHOULD_NOT: 'should_not',
};

const FILTER_META = [
  { state: 'accountState', esKey: 'project.id' },
  { state: 'regionState', esKey: 'region.keyword' },
  { state: 'cloudPlatformState', esKey: 'cloud_type.keyword' },
  { state: 'productState', esKey: 'product.keyword' },
  { state: 'vpcState', esKey: 'vpc_id.keyword' },
  { state: 'selectedInstanceType', esKey: 'instance_type.keyword' },
  { state: 'opState', esKey: 'cost.available_operation' },
  { state: 'utState', esKey: 'cost.available_usagetype' },
];

export const FILTER_META_DATA = {
  [COST_TYPE.AWS_ACCOUNTS]: 'accountState',
  [COST_TYPE.REGIONS]: 'regionState',
  [COST_TYPE.AWS_PRODUCTS]: 'productState',
  [COST_TYPE.OPERATIONS]: 'opState',
  [COST_TYPE.USAGE_TYPES]: 'utState',
};

export function isStartOfMonth() {
  const currentDayOfMonth = moment().date();

  return currentDayOfMonth === 1 || currentDayOfMonth === 2;
}

export function getLastScanValue(date) {
  const scanDate = date ? moment(date) : moment();

  return scanDate.format(FILTER_LAST_SCAN);
}

export function getFilterQuery(filterPayload) {
  return get(filterPayload, 'searchString', '*') || '*';
}

export function getFilterStartDate(filterPayload = {}, startDateDefault = LAST_30_DAYS) {
  return get(filterPayload, 'startDate', startDateDefault);
}

export function getFilterEndDate(filterPayload = {}, endDateDefault = YESTERDAY) {
  return get(filterPayload, 'endDate', endDateDefault);
}

export function getFilterParams(filterPayload = {}) {
  const filters = {
    'project.id': [],
    'region.keyword': [],
    'product.keyword': [],
    'vpc_id.keyword': [],
    'cost.available_operation': [],
    'cost.available_usagetype': [],
    'instance_type.keyword': [],
  };

  FILTER_META.forEach(filter => {
    if (filterPayload[filter.state]) {
      filters[filter.esKey] = filterPayload[filter.state];
    }
  });
  Object.keys(filters).forEach(filterKey => {
    filters[filterKey].length === 0 && delete filters[filterKey];
  });

  return filters;
}

export function getSelectedFilterKeys(obj, splitter = '-') {
  const keys = [];
  Object.keys(obj).forEach(row => {
    if (obj[row]) {
      keys.push(row.split(splitter).slice(1).join('-'));
    }
  });

  return keys;
}

export const changeDateToStringFormat = (date = undefined) => {
  if (!date) {
    return moment().format(FILTER_DATE_FORMAT);
  }

  return moment(date, FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT);
};

export const substractMonths = amount => {
  return moment.utc().startOf('month').subtract(amount, 'month');
};

export const getChargebackHistoryMonths = (startDate = null, endDate = null) => {
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
    return [];
  }

  const dateArray = [];
  const tmpDate = moment(startDate);

  while (tmpDate.format(FILTER_DATE_FORMAT) <= endDate.format(FILTER_DATE_FORMAT)) {
    dateArray.push(tmpDate.toISOString());
    tmpDate.add(1, 'month');
  }

  return dateArray;
};

export const getChargebackMonths = (startDate = null, endDate = null) => {
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
    return [];
  }

  const dateArray = [],
    formattedArray = [];
  const tmpDate = moment(startDate);

  while (tmpDate.format(FILTER_DATE_FORMAT) <= endDate.format(FILTER_DATE_FORMAT)) {
    dateArray.push(tmpDate.format('MMMM, YYYY'));
    formattedArray.push(tmpDate.format(FILTER_DATE_FORMAT));
    tmpDate.add(1, 'month');
  }

  return { dateArray, formattedArray };
};

export const getChargebackQuarters = (startDate = null, endDate = null) => {
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
    return [];
  }

  const quarterKeys = {};

  const dateArray = [],
    formattedArray = [];
  const tempDate = moment(startDate);

  while (tempDate.format(FILTER_DATE_FORMAT) <= endDate.format(FILTER_DATE_FORMAT)) {
    const tempMonth = tempDate.format('MM');
    const tempYear = tempDate.format('YYYY');

    if (['01', '02', '03'].includes(tempMonth) && !quarterKeys[`Q1, ${tempYear}`]) {
      dateArray.push(`Q1, ${tempYear}`);
      quarterKeys[`Q1, ${tempYear}`] = 'exists';
      formattedArray.push(tempDate.format(FILTER_DATE_FORMAT));
    }

    if (['04', '05', '06'].includes(tempMonth) && !quarterKeys[`Q2, ${tempYear}`]) {
      dateArray.push(`Q2, ${tempYear}`);
      quarterKeys[`Q2, ${tempYear}`] = 'exists';
      formattedArray.push(tempDate.format(FILTER_DATE_FORMAT));
    }

    if (['07', '08', '09'].includes(tempMonth) && !quarterKeys[`Q3, ${tempYear}`]) {
      dateArray.push(`Q3, ${tempYear}`);
      quarterKeys[`Q3, ${tempYear}`] = 'exists';
      formattedArray.push(tempDate.format(FILTER_DATE_FORMAT));
    }

    if (['10', '11', '12'].includes(tempMonth) && !quarterKeys[`Q4, ${tempYear}`]) {
      dateArray.push(`Q4, ${tempYear}`);
      quarterKeys[`Q4, ${tempYear}`] = 'exists';
      formattedArray.push(tempDate.format(FILTER_DATE_FORMAT));
    }

    tempDate.add(1, 'month');
  }

  return { dateArray, formattedArray };
};

export const getChargebackYears = () => {
  let dateArray = [],
    formattedArray = [];

  if (moment().year() >= 2024) {
    dateArray = [
      moment('2022-01-01', FILTER_DATE_FORMAT).format('YYYY'),
      moment('2023-01-01', FILTER_DATE_FORMAT).format('YYYY'),
    ];
    formattedArray = [
      moment('2022-01-01', FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT),
      moment('2023-01-01', FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT),
    ];

    return { dateArray, formattedArray };
  }

  dateArray = [
    moment('2021-01-01', FILTER_DATE_FORMAT).format('YYYY'),
    moment('2022-01-01', FILTER_DATE_FORMAT).format('YYYY'),
    moment('2023-01-01', FILTER_DATE_FORMAT).format('YYYY'),
  ];
  formattedArray = [
    moment('2021-01-01', FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT),
    moment('2022-01-01', FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT),
    moment('2023-01-01', FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT),
  ];

  return { dateArray, formattedArray };
};
