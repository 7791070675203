import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { RightCaretIcon } from 'assets/images/common';

const Controls = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;

  .np-button.small svg {
    width: 10px;
    height: 10px;
    fill: #1d2b38;
  }

  button:first-child svg {
    transform: rotate(180deg);
  }
`;

const ScrollControls = () => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const [width, setWidth] = useState(0);
  const agGridContainerRef = useRef(null);

  useEffect(() => {
    let isDown = false;
    let startX;
    let scrollLeft;

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    const mouseDownHandler = e => {
      isDown = true;
      startX = e.pageX - agGridContainerRef.current.offsetLeft;
      scrollLeft = agGridContainerRef.current.scrollLeft;
    };

    const mouseLeaveAndUpHandler = () => {
      isDown = false;
    };

    const mouseMoveHandler = e => {
      if (!isDown) {
        return;
      }

      e.preventDefault();

      const x = e.pageX - agGridContainerRef.current.offsetLeft;
      const walk = (x - startX) * 2; // Adjust the multiplier as needed
      agGridContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const initDragScroll = () => {
      agGridContainerRef.current = document.getElementsByClassName('ag-center-cols-viewport')?.[0] || {};

      if (!agGridContainerRef.current || typeof agGridContainerRef.current.addEventListener !== 'function') {
        return;
      }

      const { scrollWidth, clientWidth } = agGridContainerRef.current;
      const hasOverflow = scrollWidth > clientWidth;
      setHasOverflow(hasOverflow);

      if (hasOverflow) {
        agGridContainerRef.current.style.cursor = 'all-scroll';
      }

      // Add event listeners
      agGridContainerRef.current.addEventListener('mousedown', mouseDownHandler);
      agGridContainerRef.current.addEventListener('mouseleave', mouseLeaveAndUpHandler);
      agGridContainerRef.current.addEventListener('mouseup', mouseLeaveAndUpHandler);
      agGridContainerRef.current.addEventListener('mousemove', mouseMoveHandler);
    };

    // Initialize drag scroll on mount
    initDragScroll();

    // Add resize listener
    window.addEventListener('resize', updateWindowDimensions);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);

      if (agGridContainerRef.current && typeof agGridContainerRef.current.removeEventListener === 'function') {
        agGridContainerRef.current.removeEventListener('mousedown', mouseDownHandler);
        agGridContainerRef.current.removeEventListener('mouseleave', mouseLeaveAndUpHandler);
        agGridContainerRef.current.removeEventListener('mouseup', mouseLeaveAndUpHandler);
        agGridContainerRef.current.removeEventListener('mousemove', mouseMoveHandler);
      }
    };
  }, []);

  useEffect(() => {
    let agGridContainer = document.getElementsByClassName('ag-center-cols-viewport')?.[0] || {};
    const { scrollWidth, clientWidth } = agGridContainer;
    const hasOverflow = scrollWidth > clientWidth;
    setHasOverflow(hasOverflow);

    return () => {
      agGridContainer = null;
    };
  }, [width]);

  const scrollContainerBy = distance => {
    document.getElementsByClassName('ag-center-cols-viewport')[0].scrollBy({ left: distance, behavior: 'smooth' });
  };

  if (!hasOverflow) {
    return null;
  }

  return (
    <Controls>
      <button
        type="button"
        className="np-button small"
        onClick={() => {
          scrollContainerBy(-300);
        }}
      >
        <RightCaretIcon /> Scroll
      </button>
      <button
        type="button"
        className="np-button small"
        onClick={() => {
          scrollContainerBy(300);
        }}
      >
        Scroll <RightCaretIcon />
      </button>
    </Controls>
  );
};

export default ScrollControls;
