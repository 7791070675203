import get from 'lodash/get';
import qs from 'query-string';

export function getParamFromQueryString(locationSearch = '', key) {
  return get(qs.parse(locationSearch), key, undefined);
}

export function getNewQueryString(locationSearch = '', newParamParsed = {}) {
  const parsed = qs.parse(locationSearch);

  return qs.stringify({
    ...parsed,
    ...newParamParsed,
  });
}

export function removeQueryString(search, key) {
  const params = new URLSearchParams(search);

  if (params) {
    params.delete(key);
  }

  return params.toString();
}

export const getParsedSearchParams = locationSearch => {
  return qs.parse(locationSearch);
};
