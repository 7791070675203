import { getEnv, PRODUCTION_ENV } from './env';

const env = getEnv();
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const securityDashboardID = env === PRODUCTION_ENV ? 45 : 4845;
export const hipaaDashboardID = env === PRODUCTION_ENV ? 49 : 6768;
export const soc2DashboardID = env === PRODUCTION_ENV ? 52 : 7115;
export const cisDashboardID = env === PRODUCTION_ENV ? 1337 : 10329;

export const datePickerProps = {
  // input related props
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: 'horizontal',
  anchorDirection: 'left',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,

  // day presentation and interaction related props
  renderDayContents: null,
  minimumNights: 0,
  enableOutsideDays: false,

  // internationalization
  displayFormat: 'MM/DD/YYYY',
  startDatePlaceholderText: 'MM/DD/YYYY',
  endDatePlaceholderText: 'MM/DD/YYYY',
  monthFormat: 'MMMM YYYY',
};

// minimumNights
export const singlePickerProps = {
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showDefaultInputIcon: false,
  customInputIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: 'horizontal',
  anchorDirection: 'left',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,

  // day presentation and interaction related props
  renderDayContents: null,
  enableOutsideDays: false,

  // internationalization
  displayFormat: 'MM/DD/YYYY',
  monthFormat: 'MMMM YYYY',
};

// We want to use these constants in the whole app (navbar, sidebar, page title, etc.)
export const PAGE_TITLE = {
  COMPUTE_COPILOT_SHARESAVE: 'Compute Copilot Summary',
  ESSENTIALS_SHARESAVE: 'Essentials Summary',
  CM: 'Commitment Management',
};

export const PAGE_SUBTITLE = {
  COMPUTE_COPILOT_SHARESAVE:
    'All the savings Opportunities and Realizations for EKS, ASG and Commitment Management by nOps.',
  ESSENTIALS_SHARESAVE: 'All the savings Opportunities and Realizations for Essentials by nOps.',
  CM: 'Configure Commitment Manager and reduce your costs on auto-pilot.',
};

export const ROUTES_PATH = {
  RESOURCES: '/v3/resources-cost/',
  TAG_EXPLORER: '/v3/cost/tag-explorer/',
  CHARGEBACKS: '/v3/cost/chargeback/',
  CHARGEBACKS_V2: '/v3/cost/chargebacks-center-legacy/',
  CHARGEBACK_CENTER: '/v3/cost/chargebacks-center/',
  CHARGEBACK_CENTER_V2: '/v3/cost/chargebacks-center-legacy/',
  WORKLOAD_V2: '/v3/workload-v2/',
  ESSENTIALS_SHARESAVE: '/v3/essentials/summary/',
  SCHEDULER_DASHBOARD: '/v3/essentials/scheduler/',
  STORAGE_DASHBOARD: '/v3/essentials/storage/',
  RIGHTSIZING_DASHBOARD: '/v3/essentials/rightsizing/',
  IDLE_RESOURCES_DASHBOARD: '/v3/essentials/idle-resources/',
  CM_DASHBOARD: '/v3/compute-copilot/cm/dashboard/',
  EKS_DASHBOARD: '/v3/compute-copilot/eks/dashboard/',
  EKS_DETAILS: '/v3/compute-copilot/eks/details/',
  ASG_DASHBOARD: '/v3/compute-copilot/asg/dashboard/',
  VISIBILITY_DASHBOARD: '/v3/compute-copilot/visibility-dashboard/',
};

export const COST_TYPE = {
  AWS_ACCOUNTS: 'Cloud Accounts',
  CLOUD_ACCOUNTS: 'Cloud Accounts',
  REGIONS: 'Regions',
  AWS_PRODUCTS: 'Cloud Services',
  CLOUD_PRODUCTS: 'Cloud Services',
  RESOURCES: 'Resources',
  NON_RESOURCES: 'Non-resources',
  USAGE_TYPES: 'Usage Types',
  OPERATIONS: 'Operations',
  TAGS: 'Tags',
  BUDGETS: 'Chargebacks Center',
  CM: 'Change Management',
  TAGGED_RESOURCES: 'Tagged resources',
  UNTAGGED_RESOURCES: 'Untagged resources',
};

export const CT_TYPE = {
  BY_EVENT: 'BY_EVENT',
  BY_USERNAME: 'BY_USERNAME',
  BY_RESOURCE_TYPE: 'BY_RESOURCE_TYPE',
};

export const COST_AGGS = {
  [COST_TYPE.CLOUD_ACCOUNTS]: 'arp_projects',
  [COST_TYPE.AWS_ACCOUNTS]: 'arp_projects',
  [COST_TYPE.REGIONS]: 'arp_regions',
  [COST_TYPE.AWS_PRODUCTS]: 'arp_products',
  [COST_TYPE.CLOUD_PRODUCTS]: 'arp_products',
  [COST_TYPE.RESOURCES]: 'arp_resources',
  [COST_TYPE.NON_RESOURCES]: 'arp_billing_type_products',
  [COST_TYPE.USAGE_TYPES]: 'arp_usagetypes',
  [COST_TYPE.OPERATIONS]: 'arp_operations',
  [COST_TYPE.TAGS]: undefined,
  [COST_TYPE.BUDGETS]: undefined,
};

export const COST_AGGS_AZURE = {
  [COST_TYPE.CLOUD_ACCOUNTS]: 'arp_billing_type_projects',
  [COST_TYPE.AWS_ACCOUNTS]: 'arp_billing_type_projects',
  [COST_TYPE.REGIONS]: 'arp_billing_type_regions',
  [COST_TYPE.CLOUD_PRODUCTS]: 'arp_billing_type_products',
  [COST_TYPE.AWS_PRODUCTS]: 'arp_billing_type_products',
  [COST_TYPE.RESOURCES]: 'arp_resources',
  [COST_TYPE.NON_RESOURCES]: 'arp_billing_type_products',
  [COST_TYPE.USAGE_TYPES]: 'arp_billing_type_usage_types',
  [COST_TYPE.OPERATIONS]: 'arp_billing_type_operations',
  [COST_TYPE.TAGS]: undefined,
  [COST_TYPE.BUDGETS]: undefined,
};

export const RULE_PATH = {
  RESOURCES: '/v3/rules/',
};

// all supported cloud platforms
export const ALL_CLOUD_PLATFORM_KEYS = ['aws', 'azure'];

// display names of cloud platforms
export const CLOUD_TYPE = {
  AWS: 'AWS',
  AZURE: 'Azure',
};

export const isAzure = cloud_type => (cloud_type || '').toLowerCase() === 'azure';

export const ALL_CLOUD_PLATFORMS = ALL_CLOUD_PLATFORM_KEYS.map(cloudPlatform => ({
  id: cloudPlatform,
  value: CLOUD_TYPE[cloudPlatform.toUpperCase()],
  cloudPlatform: cloudPlatform,
}));

export const defaultUnassigned = {
  icon: 'http://www.gravatar.com/avatar/e0026290dd527af8a4bfda00639ed886.jpg?s=100&d=mm',
  value: 0,
  label: 'Unassigned',
};

export const EVENTBRIDGE_REGIONS = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ca-central-1',
  'eu-central-1',
  'eu-central-2',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-north-1',
  'eu-south-1',
  'eu-south-2',
  'ap-south-1',
  //removed it because we no longer support it, kept it commented just to have the list
  // 'af-south-1',
  // 'ap-south-2',
  // 'ap-east-1',
  // 'ap-northeast-1',
  // 'ap-northeast-2',
  // 'ap-northeast-3',
  // 'ap-southeast-1',
  // 'ap-southeast-2',
  // 'ap-southeast-3',
  // 'ap-southeast-4',
  'cn-north-1',
  'cn-northwest-1',
  'me-central-1',
  'me-south-1',
  'sa-east-1',
  'il-central-1',
];

export const REGIONS = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ca-central-1',
  'eu-central-1',
  'eu-central-2',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-north-1',
  'eu-south-1',
  'eu-south-2',
  'ap-south-1',
  'af-south-1',
  'ap-south-2',
  'ap-east-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'cn-north-1',
  'cn-northwest-1',
  'me-central-1',
  'me-south-1',
  'sa-east-1',
  'il-central-1',
];

export const MAP_SERVICES = [
  {
    label: 'Amazon API Gateway',
    value: 'AmazonApiGateway',
  },
  {
    label: 'Amazon AppStream',
    value: 'AmazonAppStream',
  },
  {
    label: 'AWS AppSync',
    value: 'AWSAppSync',
  },
  {
    label: 'Amazon Athena',
    value: 'AmazonAthena',
  },
  {
    label: 'AWS Backup',
    value: 'AWSBackup',
  },
  {
    label: 'AWS Certificate Manager',
    value: 'AWSCertificateManager',
  },
  {
    label: 'Amazon Cloud Directory',
    value: 'AmazonCloudDirectory',
  },
  {
    label: 'AWS CloudHSM',
    value: 'CloudHSM',
  },
  {
    label: 'Amazon CloudWatch',
    value: 'AmazonCloudWatch',
  },
  {
    label: 'AWS CodeBuild',
    value: 'CodeBuild',
  },
  {
    label: 'AWS CodePipeline',
    value: 'AWSCodePipeline',
  },
  {
    label: 'AWS CodeStar',
    value: 'AWSCodeStar',
  },
  {
    label: 'Amazon Cognito Identity',
    value: 'AmazonCognito',
  },
  {
    label: 'Amazon Cognito User Pools',
    value: 'AmazonCognitoIdentity',
  },
  {
    label: 'Amazon Comprehend',
    value: 'Comprehend',
  },
  {
    label: 'AWS Data Pipeline',
    value: 'DataPipeline',
  },
  {
    label: 'AWS Database Migration Service',
    value: 'AWSDatabaseMigrationSvc',
  },
  {
    label: 'AWS DataSync',
    value: 'AWSDataSync',
  },
  {
    label: 'AWS Direct Connect',
    value: 'AWSDirectConnect',
  },
  {
    label: 'AWS Directory Service',
    value: 'AWSDirectoryService',
  },
  {
    label: 'Amazon DynamoDB',
    value: 'AmazonDynamoDB',
  },
  {
    label: 'Amazon Elastic Compute Cloud',
    value: 'AmazonEC2',
  },
  {
    label: 'Amazon Elastic Container Registry',
    value: 'AmazonECR',
  },
  {
    label: 'Amazon Elastic Kubernetes Service',
    value: 'AmazonEKS',
  },
  {
    label: 'Amazon Elastic Container Service',
    value: 'AmazonECS',
  },
  {
    label: 'AWS Elastic Beanstalk',
    value: 'AWSElasticBeanstalk',
  },
  {
    label: 'Amazon Elastic File System',
    value: 'AmazonEFS',
  },
  {
    label: 'Elastic Load Balancing',
    value: 'AWSELB',
  },
  {
    label: 'Amazon ElastiCache',
    value: 'AmazonElastiCache',
  },
  {
    label: 'Amazon EMR',
    value: 'ElasticMapReduce',
  },
  {
    label: 'Amazon FSx',
    value: 'AmazonFSx',
  },
  {
    label: 'Amazon S3 Glacier',
    value: 'AmazonGlacier',
  },
  {
    label: 'AWS Glue',
    value: 'AWSGlue',
  },
  {
    label: 'AWS Key Management Service',
    value: 'awskms',
  },
  {
    label: 'Amazon Kinesis Data Streams',
    value: 'AmazonKinesis',
  },
  {
    label: 'Amazon Kinesis Data Analytics',
    value: 'AmazonKinesisAnalytics',
  },
  {
    label: 'Amazon Kinesis Data Firehose',
    value: 'AmazonKinesisFirehose',
  },
  {
    label: 'AWS Lambda',
    value: 'AWSLambda',
  },
  {
    label: 'Amazon MQ',
    value: 'AmazonMQ',
  },
  {
    label: 'Amazon Managed Streaming for Apache Kafka',
    value: 'AmazonMSK',
  },
  {
    label: 'Amazon Neptune',
    value: 'AmazonNeptune',
  },
  {
    label: 'AWS Network Firewall',
    value: 'AWSNetworkFirewall',
  },
  {
    label: 'Amazon OpenSearch Service',
    value: 'AmazonES',
  },
  {
    label: 'Amazon Redshift',
    value: 'AmazonRedshift',
  },
  {
    label: 'Amazon Relational Database Service',
    value: 'AmazonRDS',
  },
  {
    label: 'Amazon Route 53',
    value: 'AmazonRoute53',
  },
  {
    label: 'Amazon Simple Storage Service',
    value: 'AmazonS3',
  },
  {
    label: 'Amazon SageMaker',
    value: 'AmazonSageMaker',
  },
  {
    label: 'AWS Secrets Manager',
    value: 'AWSSecretsManager',
  },
  {
    label: 'AWS Security Hub',
    value: 'AWSSecurityHub',
  },
  {
    label: 'Amazon Simple Notification Service',
    value: 'AmazonSNS',
  },
  {
    label: 'Amazon Simple Queue Service',
    value: 'AWSQueueService',
  },
  {
    label: 'AWS Step Functions',
    value: 'AmazonStates',
  },
  {
    label: 'AWS Storage Gateway',
    value: 'AWSStorageGateway',
  },
  {
    label: 'AWS Systems Manager',
    value: 'AWSSystemsManager',
  },
  {
    label: 'AWS Transfer Family',
    value: 'AWSTransfer',
  },
  {
    label: 'Amazon Virtual Private Cloud',
    value: 'AmazonVPC',
  },
  {
    label: 'Amazon WorkSpaces',
    value: 'AmazonWorkSpaces',
  },
  {
    label: 'Amazon CloudFront',
    value: 'AmazonCloudFront',
  },
  {
    label: 'Amazon Kendra',
    value: 'AmazonKendra',
  },
  {
    label: 'Amazon Keyspaces (for Apache Cassandra)',
    value: 'AmazonMCS',
  },
  {
    label: 'AWS Mainframe Modernization',
    value: 'AWSM2',
  },
  {
    label: 'AWS Elastic Disaster Recovery',
    value: 'AWSElasticDisasterRecovery',
  },
  {
    label: 'AWS Elemental MediaLive',
    value: 'AWSElementalMediaLive',
  },
  {
    label: 'AWS Elemental MediaPackage',
    value: 'AWSElementalMediaPackage',
  },
  {
    label: 'AWS Elemental MediaConvert',
    value: 'AWSElementalMediaConvert',
  },
  {
    label: 'Amazon DocumentDB (with MongoDB compatibility)',
    value: 'AmazonDocDB',
  },
  {
    label: 'Amazon Omics',
    value: 'AmazonOmics',
  },
  {
    label: 'Amazon Timestream',
    value: 'AmazonTimestream',
  },
  {
    label: 'Amazon QuickSight',
    value: 'AmazonQuickSight',
  },
  {
    label: 'AWS Resilience Hub',
    value: 'AWSResilienceHub',
  },
  {
    label: 'Amazon FinSpace',
    value: 'AmazonFinSpace',
  },
  {
    label: 'Amazon GameLift',
    value: 'AmazonGameLift',
  },
  {
    label: 'Amazon MemoryDB for Redis',
    value: 'AmazonMemoryDB',
  },
  {
    label: 'AWS HealthImaging',
    value: 'AmazonMedicalImaging',
  },
  {
    label: 'Amazon VPC Lattice',
    value: 'AmazonVPC',
  },
];

export const AG_GRID_HEADER_PARAMS = {
  template:
    '<div class="ag-cell-label-container" role="presentation">' +
    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>',
};

export const AG_GRID_DEFAULT_CONFIG = {
  sortable: true,
  unSortIcon: true,
  filter: true,
  autoHeight: true,
  resizable: true,
  wrapText: true,
  suppressMenuHide: true,
  filterParams: {
    newRowsAction: 'keep',
    defaultToNothingSelected: true,
    buttons: ['clear', 'apply'],
    closeOnApply: true,
  },
  headerComponentParams: { ...AG_GRID_HEADER_PARAMS },
  menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab'],
  headerClass: params => {
    if (params.column.colDef.sortable) {
      return 'sortable-header';
    }

    if (params.column.colDef.filter) {
      return 'filterable-header';
    }

    return '';
  },
};

export const AG_GRID_IMMUTABLE_CONFIG = {
  immutableData: true,
  getRowNodeId: data => data.id,
};

export const AG_GRID_NO_ROWS_TEMPLATE =
  '<span style="color: #95a0af; font-size: 14px; font-weight: 500;">No results found</span>';

export const getAgGridNoRowsTemplate = message =>
  `<span style="color: #95a0af; font-size: 14px; font-weight: 500;">${message}</span>`;

export const START_OF_THE_QUARTER_WARNING_MESSAGE =
  'The new quarter has begun and we are waiting for AWS to publish the data.';

export const DOC_LINKS = {
  API_KEY: 'https://help.nops.io/developer-getting-started.html',
  SUPPORT_CASE: 'https://help.nops.io/docs/support/open-support-case',
  GETTING_STARTED: 'https://help.nops.io/docs/nops/getting-started/',
  TERRAFORM: 'https://help.nops.io/docs/getting-started/Onboarding/onboarding-aws-with-terraform',
  CLOUD_FORMATION: 'https://help.nops.io/docs/getting-started/Onboarding/onboarding-aws-with-automatic-setup',
};

export const MEETING_LINKS = {
  ONBOARDING: 'https://nops-io.na.chilipiper.com/book/me/dilber-khan?type=my-meeting',
  CM: 'https://nops-io.na.chilipiper.com/book/commitment-management-configuration',
};
