import axiosObj from './config';
import { getFormData, getURLParams } from './all-api-helper';

export function getSummaryData(params) {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/cost_summary/',
    params: getURLParams(params),
  });
}

export function getCloudResourceCost({ params }) {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/dimension_daily_cost/',
    params: getURLParams(params),
  });
}

export function setCloudResourceCost({ params, dimension }) {
  return axiosObj({
    method: 'POST',
    url: `/c/cost_page/dimension_daily_cost/?dimension=${dimension}`,
    data: getFormData(params),
  });
}

export function getCloudResourceCostForInventory(payload) {
  return axiosObj({
    method: 'POST',
    url: '/c/cost_page/dimension_daily_cost/?dimension=line_item_resource_id',
    data: payload,
  });
}

export function getDimensionDatForTags({ params }) {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/tag_values_granularity/',
    params: getURLParams(params),
  });
}

export function getDimensionDataForLabels({ params }) {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/label_values_granularity/',
    params: getURLParams(params),
  });
}

export function getDimensionDataForCostAllocationTag({ params }) {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/cost_allocation_tag_values_granularity/',
    params: getURLParams(params),
  });
}

export function getReports() {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/reports/?is_hidden=false',
  });
}

export function getReportById(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/cost_page/reports/${id}/`,
  });
}

export function getGlobalReports() {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/global_reports/',
  });
}

export function getAllHiddenReport() {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/reports/?is_hidden=true',
  });
}

export function getHiddenReport(share_code) {
  return axiosObj({
    method: 'GET',
    url: `/c/cost_page/reports/?is_hidden=true&share_code=${share_code}`,
  });
}

export function shareReports(id, data) {
  return axiosObj({
    method: 'POST',
    url: `/c/cost_page/reports/${id}/send/`,
    data,
  });
}

export function saveReports(data) {
  return axiosObj({
    method: 'POST',
    url: '/c/cost_page/reports/',
    data,
  });
}

export function editReports(data, id) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/cost_page/reports/${id}/`,
    data,
  });
}

export function deleteReports(id) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/cost_page/reports/${id}/`,
  });
}

export function configReports(data) {
  return axiosObj({
    method: 'POST',
    url: '/notification/rest/nops_notifications/',
    data,
  });
}

export function getConfigReports(data) {
  return axiosObj({
    method: 'GET',
    url: '/notification/rest/nops_notifications/',
    data,
  });
}

export function updateConfigReports(data, id) {
  return axiosObj({
    method: 'PATCH',
    url: `/notification/rest/nops_notifications/${id}/`,
    data,
  });
}

export function testConfigReports(id) {
  return axiosObj({
    method: 'GET',
    url: `/notification/rest/nops_notifications/${id}/send_test`,
  });
}

export function deleteConfigReports(id) {
  return axiosObj({
    method: 'DELETE',
    url: `/notification/rest/nops_notifications/${id}/`,
  });
}

export function getTargets() {
  return axiosObj({
    method: 'GET',
    url: '/c/cost_page/cost_target/',
  });
}

export function getTargetById(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/cost_page/cost_target/${id}/`,
  });
}

export function saveTargets({ data, id }) {
  let url = '/c/cost_page/cost_target/',
    method = 'POST';

  if (id) {
    url = `/c/cost_page/cost_target/${id}/`;
    method = 'PATCH';
  }

  return axiosObj({
    method,
    url,
    data,
  });
}

export function saveTargetDetail(id) {
  return axiosObj({
    method: 'POST',
    url: `/c/cost_page/cost_target/${id}/create_cost_target_plannings/`,
  });
}

export function uploadCSVTarget({ id, data }) {
  return axiosObj({
    method: 'POST',
    url: `/c/cost_page/cost_target/${id}/upload_csv/`,
    data,
  });
}

export function deleteTargets(id) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/cost_page/cost_target/${id}/`,
  });
}

export function getTargetPlanning(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/cost_page/cost_target_planning/?cost_target=${id}`,
  });
}

export function getTargetPlanningHistory(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/cost_page/cost_target_planning_history/?cost_target=${id}`,
  });
}

export function saveTargetPlanning({ data, id }) {
  let url = '/c/cost_page/cost_target_planning/',
    method = 'POST';

  if (id) {
    url = `/c/cost_page/cost_target_planning/${id}/`;
    method = 'PATCH';
  }

  return axiosObj({
    method,
    url,
    data,
  });
}

export function deleteTargetPlanning(id) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/cost_page/cost_target_planning/${id}/`,
  });
}
